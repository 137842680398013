import React, { useState } from 'react';
import { graphql } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import Hero from 'components/Tools/Hero';
import DescriptionBlock from 'components/Tools/DescriptionBlock';
import SeerEnergyCalculator from 'components/Tools/EnergySavings';
import { createToolsBreadcrumbs } from './utils/createToolsBreadcrumbs';
import BookmarkContext from 'utils/BookmarkContext';

const SeersEnergyCalculator = (props) => {
  const data = props.data.contentfulTool;
  const pathUrl = props.location;
  const breadcrumbs = createToolsBreadcrumbs('Energy Savings Calculator');
  const bookmarkData = {
    title: data.internalName,
    image: data?.cardImage?.image,
    subTitle: 'Tool',
    url: props.path,
    type: 'tool',
  };
  const [selectedCalc, setSelectedCalc] = useState('cool');

  const handleToggleClick = (value) => {
    setSelectedCalc(value);
  };

  return (
    <BookmarkContext.Provider value={bookmarkData}>
      <ToolboxLayout breadcrumbs={breadcrumbs} contentfulSeo={data.seo} layout="toolbox" {...props}>
        <Hero data={data.hero} backgroundImageName="" className={selectedCalc} />
        <SeerEnergyCalculator
          pathUrl={pathUrl}
          gatedForm={data.gatedForm}
          handleToggleClick={handleToggleClick}
        />
        {data.description && <DescriptionBlock data={data.description} />}
      </ToolboxLayout>
    </BookmarkContext.Provider>
  );
};

export default SeersEnergyCalculator;

export const SeersEnergyCalculatorQuery = graphql`
  query SeersEnergyCalculatorQuery {
    contentfulTool(internalName: { eq: "SEER Energy Savings Calculator" }) {
      ...Tool
    }
  }
`;
